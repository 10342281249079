<template>
  <div class="marketing">
    <div class="product_desc_1 box_pad">
      <h3>数字营销平台</h3>
      <p>
        是基于一物一码数字身份管理方式，开展产品在渠道，消费者中的数字营销活动。运用利益刺激，情感互联，品牌宣传等互动方式，帮助企业获得各平台互动流量及粉丝关注，直至最终转化为产品销售，与持续消费黏性。实现企业与消费者共盈的数字生态，拓宽企业营销生态。
      </p>
      <div class="desc_img"></div>
    </div>
    <div class="product_desc_2 box_pad">
      <h3>数字营销价值</h3>
      <p>
        <span>智能营销策略:</span>
        企业可以根据不同的运营场景，匹配不同的规则策略，实现精准化、差异化的营销，让品牌的每一分促销成本都花在刀刃上，实现精准营销。
      </p>
      <p>
        <span>营销活动多样性:</span>
        跨界营销、会员积分、互动抽奖、红包、优惠等线上线下活动，并能通过连续扫码、会员积分、连续签到等进行累积扫码，用于对消费者的粘性维护。
      </p>
      <div class="desc_img"></div>

      <p>
        <span>实时调整营销策略:</span>
        活动可根据产品、时间、地点快速部署。实时监控每个维度的活动的效果，品牌可以根据活动报表随时调整活动设置，更灵活、更实时的。
      </p>
      <p>
        <span>个性化用户体验:</span>
        扫码带来海量活动数据，数据通过分析产生有价值的消费者洞察，品牌可据此进行精准营销，提升个性化用户体验。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.marketing {
  .box_pad {
    padding: 50px 30px;
  }
  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin-bottom: 20px;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/marketing_info.png") no-repeat
      center/contain;
    border-radius: 10px;
    margin: 10px 0;
    margin-bottom: 20px;
  }
  .product_desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      background: url("~@/assets/img/product/marketing_info_2.jpg") no-repeat
        center/cover;
    }
  }
}
</style>